.heading{
    font-size: 48px;
    padding-top: 155px;
    width: 700px;
    color: rgb(60, 102, 141);
    margin: auto;
    font-weight: bold;
}

.sub_text{
    font-size: 20px;
    color: rgb(58, 95, 126);
    margin-top: 23px;
    margin-bottom: 90px;
}
.logo_head{
    font-size: 22px;
    font-weight: bold;
    color: rgb(58, 95, 126);
    margin-top: 24px;
}
.logo_text{
    font-size: 16px;
    color: rgb(17,17,17);
    margin-top: 19px;
    margin-left: 40px;
    margin-right: 40px;
}
.sub{
    margin-top: 113px;
    padding-bottom: 125px;
}

@media screen and (max-width: 1000px) {
    .heading{
        font-size: 35px;
        padding-top: 100px;
    }
    .sub{
        padding-bottom: 100px;
        margin-top: 80px;
    }
    .sub_text{
        margin-bottom: 70px;
    }
}

@media screen and (max-width: 770px) {
    .heading{
        font-size: 25px;
        padding-top: 70px;
        width: 400px;
    }
    .sub_text{
        font-size: 17px;
    }
    .sub{
        padding-bottom: 70px;
        margin-top: 60px;
    }
    .logo_head{
        font-size: 18px;
        margin-top: 8px;
        margin-bottom: 0px;
    }
    .logo_text{
        font-size: 18px;
        margin-top: 8px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 430px) {

    .heading{
        font-size: 18px;
        padding-top: 50px;
        width: 100%;
    }
    .sub_text{
        font-size: 13px;
        margin-top: 5px;
    }
    .logo_head{
        font-size: 15px;
    }
    .sub{
        padding-bottom: 13px;
    }
    .icon {
        color: white;
        margin-bottom: 10px;
    }
    
}