/* Container with padding */
.contain {
    padding-bottom: 130px;
}

/* Row styling for layout */
.row {
    margin-top: 130px !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

/* Heading styling */
.head, .head1 {
    font-family: 'Poppins', sans-serif;
    font-size: 35px;
    font-weight: bold;
    color: rgb(42, 84, 112);
    text-align: left;
    margin-top: 135px;
    margin-bottom: 10px; /* Add margin to separate text from images */
    width: 100%;
}

/* Paragraph content styling */
.content, .content1 {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    color: rgb(0, 1, 2);
    opacity: 0.7;
    text-align: left;
    margin-bottom: 20px; /* Add margin to separate text from images */
    width: 100%;
}

/* Image styling */
.img, .img1 {
    width: 70%;  /* Adjust the width to 70% */
    height: auto; /* Maintain aspect ratio */
    margin-left: auto; /* Center the images */
    margin-right: auto; /* Center the images */
    display: block; /* Ensure images are block elements for centering */
    margin-bottom: 50px; /* Add space below the images */
}

/* Media queries for responsiveness */
@media screen and (max-width: 992px) {
    .head, .head1 {
        margin-left: 0px;
        margin-top: 90px;
        text-align: center; /* Center text for smaller screens */
    }

    .content, .content1 {
        margin-left: 0px;
        text-align: center; /* Center text for smaller screens */
    }

    .img, .img1 {
        width: 60%; /* Further reduce the image width for medium screens */
    }

    .row {
        margin-top: 80px !important;
    }

    .contain {
        padding-bottom: 80px;
    }
}

@media screen and (max-width: 768px) {
    .img, .img1 {
        width: 50%; /* Further reduce image size for smaller screens */
    }

    .head, .head1 {
        font-size: 22px;
        margin-top: 30px;
        text-align: center;
    }

    .content, .content1 {
        font-size: 18px;
        text-align: center;
    }

    .row {
        margin-top: 50px !important;
    }
}
