/* General Styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f0f4f8;
  color: #2c3e50;
}

.blog-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Hero Section */
.hero-section {
  background: linear-gradient(135deg, #3498db, #2980b9);
  padding: 60px 20px;
  text-align: center;
  color: white;
  margin-bottom: 40px;
  border-radius: 10px;
}

.hero-section h1 {
  font-size: 48px;
  margin-bottom: 10px;
  font-weight: bold;
}

.hero-section p {
  font-size: 20px;
  margin-top: 0;
  max-width: 600px;
  margin: 0 auto;
}

/* Blog List */
.blog-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.blog-post {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: calc(33.333% - 20px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.blog-post:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.blog-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px;
}

.blog-post h2 {
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 10px;
  transition: color 0.3s ease;
}

.blog-post:hover h2 {
  color: #3498db;
}

.blog-post p {
  font-size: 16px;
  color: #7f8c8d;
  line-height: 1.6;
  margin-bottom: 15px;
}

.blog-info {
  font-size: 14px;
  color: #95a5a6;
}

.blog-info span {
  margin-right: 15px;
  font-weight: 600;
}

.blog-info span::after {
  content: '|';
  margin-left: 10px;
}

.blog-info span:last-child::after {
  content: '';
  margin-left: 0;
}

/* Blog Actions */
.blog-actions {
  margin-top: 15px;
  display: flex;
  gap: 15px;
}

.blog-actions button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.blog-actions button:hover {
  background-color: #2980b9;
  transform: scale(1.05);
}

.blog-actions i {
  margin-right: 5px;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 10px;
}

.pagination button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.pagination button.active {
  background-color: #2c3e50;
  transform: scale(1.1);
}

.pagination button:hover {
  background-color: #2980b9;
  transform: scale(1.05);
}
.share-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it’s above other content */
}

.share-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.share-modal-content button {
  margin: 5px;
  padding: 10px 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.share-modal-content button:hover {
  background-color: #2980b9;
}
