.nav_text{
    font-size: 16px;
    color: #111111 !important;
    width: 120px;
    
}
.nav_text:hover{
    padding-top: 6px;
    font-size: 18px;
    color: #305f8b !important;
    font-weight: bold;
}
.active{
    padding-top: 6px !important;
    font-size: 18px;
    color: #000000 !important;
    font-weight: bold;
}

.drop{
    width: 120px;
}

.drop:hover .nav_text{
    color: #305f8b !important;
    font-weight: bold;
    cursor: pointer;
    font-size: 18px;
    /* padding-top: 7px; */
}
.logo{
    color: #c4a38d !important;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    font-size: 40px !important;
}
.dropdownText{
    text-decoration: none;
    color: #111111;
}

.dropdownItem:hover .dropdownText{
    color: #c4a38d;
}
.drop a:hover{
    background-color: white;
}

/* Target the logo container */
.logo {
    display: flex;
    align-items: center;
  }
  
  /* Target the logo image */
  .logo img {
    height: 60px; /* Increase the height to enlarge the logo */
    width: auto;  /* Maintain the aspect ratio */
    object-fit: contain;
  }
  
  /* Responsive adjustments for the logo image */
  @media (max-width: 768px) {
    .logo img {
      height: 50px; /* Smaller size for smaller screens */
    }
  }
  
  @media screen and (max-width: 600px) {
    .logo img {
      height: 40px; /* Further reduce size for smaller devices */
    }
  }
  


@media screen and (max-width: 991px) {
    .dropdownItem{
        text-align: center !important;
    }
    .nav_text{
        width: auto;
        padding-top: 0px;
    }
    .drop{
        width: auto;
    }
    .logo{
        font-size: 35px !important;
    }
}

@media screen and (max-width: 600px) {
    .logo{
        font-size: 25px !important;
    }
}
