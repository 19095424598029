/* Import slick carousel CSS */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.imgbox {
    height: 94vh;
    position: relative;
    overflow: hidden; /* To ensure that images stay contained */
}

.slideImage {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure images fit well */
    display: block; /* Ensure images are visible */
}

.center {
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2; /* Ensure text appears above the image */
}

.text {
    font-size: 67px;
    color: #fff;
    font-weight: 400;
}

.btn {
    max-width: 272px; /* Use max-width for better responsiveness */
    height: 64px;
    font-size: 14px;
    background-color: #385c6c !important;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition */
}

.btn:hover {
    background-color: #36020b !important;
}

@media screen and (max-width: 1266px) {
    .text {
        font-size: 57px;
    }
    .btn {
        max-width: 250px;
        height: 58px;
    }
}

@media screen and (max-width: 1090px) {
    .text {
        font-size: 47px;
    }
    .btn {
        max-width: 220px;
        height: 48px;
    }
}

@media screen and (max-width: 921px) {
    .text {
        font-size: 37px;
    }
}

@media screen and (max-width: 715px) {
    .text {
        font-size: 27px;
    }
    .btn {
        max-width: 160px;
        height: 38px;
    }
}

@media screen and (max-width: 524px) {
    .text {
        width: 100%;
    }
    
    .btn {
        max-width: 140px;
        height: 36px;
        font-size: 12px;
    }
}

@media screen and (max-width: 396px) {
    .text {
        font-size: 20px;
    }
    .btn {
        max-width: 110px;
        font-size: 11px;
    }
}

.prevArrow, .nextArrow {
    position: absolute;
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Center vertically */
    z-index: 3; /* Ensure arrows are above the images */
    cursor: pointer;
    color: #fff;
    background-color: rgb(56, 79, 108);
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* Set a width for the arrow buttons */
    height: 40px; /* Set a height for the arrow buttons */
    transition: background-color 0.3s; /* Smooth transition */
}

.prevArrow {
    left: 10px; /* Position on the left side */
}

.nextArrow {
    right: 10px; /* Position on the right side */
}

.prevArrow:hover, .nextArrow:hover {
    background-color: rgba(0, 0, 0, 0.7); /* Darker on hover */
}
