.heading{
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 500;
    color: #ff4c0a;
    margin-top: 77px;
    margin-bottom: 0px;
}
.subHeading{
    font-family: 'Poppins';
    font-size: 34px;
    font-weight: bold;
    color: rgb(56, 79, 108);;
    margin-top: 36px;
}
.content{
    font-family: 'Poppins';
    font-size: 18px;
    color: #000000;
    margin-top: 14px;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 70px;
}
.contain{
    padding-left: 100px;
}
.about{
    font-family: 'Poppins';
    font-size: 50px;
    font-weight: bold;
    color: rgb(72, 96, 119);
    text-align: left;
    margin-bottom: 4px;
    margin-top: 119px;
}

.imgContain{
    margin-left: -90px;
}
.img1 {
    width: 50%; 
    height: auto; 
    max-height: 200px; 
}
.img2 {
    text-align: left;
    padding-left: 100px;
    margin-top: 14px;
    width: 50%;
    height: auto; 
    max-height: 200px; 
    margin-bottom: 82px;
}
.creativity{
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: bold;
    color: rgb(108,77,56);
    text-align: left;
}
.contn{
    font-family: 'Poppins';
    font-size: 18px;
    color: #989e9d;
    text-align: left;
}

@media screen and (max-width: 1200px) {
    .contain{
        padding-left: 40px;
    }
    .img2{
        padding-left: 40px;
    }
    .imgContain{
        margin-left: -60px;
    }
}
@media screen and (max-width: 992px) {
    .heading{
        font-size: 25px;
        margin-top: 60px;
    }
    .subHeading{
        font-size: 29px;
        margin-top: 30px;
    }
    .contain{
        padding-left: 0px;
    }
    .img2{
        padding-left: 0px;
        margin-bottom: 60px;
    }
    .imgContain{
        margin-left: -30px;
    }
    .about{
        font-size: 40px;
    }
    .creativity{
        font-size: 25px;
    }

}
@media screen and (max-width: 768px) {
    .content{
        width: 100%;
    }
    .about{
        margin-top: 80px;
    }
    .imgContain{
        margin-left: 0px;
    }
}

@media screen and (max-width: 650px) {
    .heading{
        font-size: 20px;
        margin-top: 40px;
    }
    .subHeading{
        font-size: 24px;
        margin-top: 20px;
    }
    .img2{
        margin-bottom: 40px;
    }
    .about{
        font-size: 30px;
        margin-top: 60px;
    }
    .creativity{
        font-size: 20px;
    }

    .icon {
        transition: transform 0.3s ease, color 0.3s ease;
    }
    
    .iconBlue {
        color: #007bff; /* Blue color for the icons */
    }
    
    .iconBlue:hover {
        color: #0056b3; /* Darker blue on hover */
        transform: scale(1.1); /* Slight scale-up effect on hover */
    }
    
    /* Bulb glow effect */
    .bulbEffect {
        animation: glow 1.5s infinite;
        color: #007bff; /* Same blue color as other icons */
    }
    
    @keyframes glow {
        0% {
            text-shadow: 0 0 5px #007bff, 0 0 10px #007bff, 0 0 15px #007bff;
        }
        50% {
            text-shadow: 0 0 10px #007bff, 0 0 20px #007bff, 0 0 30px #00ffef;
        }
        100% {
            text-shadow: 0 0 5px #007bff, 0 0 15px #007bff, 0 0 25px #007bff;
        }
    }
    

}