/* Shop Component Styles */

/* General Styles */
.shop-container {
  margin-top: 50px;
  padding: 0 15px; /* Add padding to keep space on the sides */
}

/* Product Cards */
.card {
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-inline-start: 60px;  /* Add margin to the start */
    margin-inline-end: 40px;    /* Add margin to the end */
  }
  

/* Hover Effect */
.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.product-img {
    width: 100%;  /* Reduce the width to 90% */
    height: 250px;  /* Increase the height to 250px */
    object-fit: cover;  /* Ensures the image doesn't distort and maintains aspect ratio */
  }
  

/* Card Body */
.card-body {
  padding: 20px;
  text-align: center;
}

/* Title and Text */
.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.card-text {
  font-size: 0.95rem;
  color: #555;
  margin-bottom: 20px;
}

/* Button Styles */
button {
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 0.875rem;
  text-transform: uppercase;
  width: 48%; /* Adjust button width */
}

/* Add to Cart & Wishlist Buttons */
button.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

button.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

button.btn-outline-danger {
  border-color: #dc3545;
  color: #dc3545;
}

button.btn-outline-danger:hover {
  background-color: #dc3545;
  color: white;
}

/* Contact Button */
button.btn-link {
  color: #007bff;
  text-decoration: none;
}

button.btn-link:hover {
  color: #0056b3;
}

/* Footer */
footer {
  background-color: #343a40;
  color: white;
  padding: 20px;
  position: relative;
  bottom: 0;
  width: 100%;
  margin-top: 50px;
}

footer .footer-content {
  max-width: 1200px; /* Make sure the footer doesn't span the entire width */
  margin: 0 auto;
  text-align: center;
}

footer p {
  font-size: 1rem;
}

footer .footer-links {
  margin-top: 10px;
}

footer .btn-link {
  color: #ffffff;
  text-decoration: none;
  margin: 0 10px;
}

footer .btn-link:hover {
  color: #007bff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .card-body {
    padding: 15px;
  }

  .footer-content {
    max-width: 100%; /* Allow full width on smaller screens */
  }
}

@media (max-width: 576px) {
  .card-title {
    font-size: 1.1rem;
  }

  .card-text {
    font-size: 0.9rem;
  }

  footer {
    padding: 20px 0;
  }
}
