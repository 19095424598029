.company-team-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.team-header {
  margin-bottom: 40px;
}

.team-header h1 {
  font-size: 36px;
  color: #333;
  margin-bottom: 10px;
}

.team-header p {
  font-size: 18px;
  color: #555;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.team-card {
  background-color: #fff;
  border-radius: 20px; /* Rounder card appearance */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content */
  text-align: center;
}

.team-card:hover {
  transform: translateY(-10px);
}

.team-image {
  width: 80px; /* Smaller image size */
  height: 80px;
  border-radius: 50%; /* Fully rounded image */
  object-fit: cover; /* Ensures image fits within the circle */
  margin-bottom: 15px;
}

h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.team-role {
  font-size: 14px;
  color: #777;
  margin-bottom: 5px;
}

.team-expertise {
  font-size: 13px;
  color: #999;
  margin-bottom: 10px;
}

.team-bio {
  font-size: 13px;
  color: #555;
}

.social-links {
  display: flex;
  gap: 15px; /* Adjust spacing between icons */
  margin-top: 10px; /* Space above the icons */
}

.social-links a {
  color: #555; /* Default color for icons */
  font-size: 24px; /* Increased size for better visibility */
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth color and scale transitions */
}

.social-links a:hover {
  color: #0077b5; /* Change color on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
}

@media (max-width: 768px) {
  .team-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .team-header h1 {
    font-size: 28px;
  }

  .team-header p {
    font-size: 16px;
  }

  .team-card {
    padding: 15px;
  }

  h3 {
    font-size: 18px;
  }

  .team-role,
  .team-expertise,
  .team-bio {
    font-size: 12px;
  }

  .team-image {
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 480px) {
  .team-grid {
    grid-template-columns: 1fr;
  }

  .team-header h1 {
    font-size: 24px;
  }

  .team-header p {
    font-size: 14px;
  }

  h3 {
    font-size: 16px;
  }

  .team-role,
  .team-expertise,
  .team-bio {
    font-size: 12px;
  }

  .team-image {
    width: 60px;
    height: 60px;
  }
}
